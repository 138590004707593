import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton, Seo, Text } from 'components';
import Arrow from 'assets/icons/results/green-arrow-right.svg';
import { useRouter } from 'apis/history';
import { useQuizData } from 'utils/hooks';
import Header from 'components/Header';
import ResultsProgress from 'components/ResultsProgress';

import first from 'assets/images/checkout/testimonials/first.mp4';
import second from 'assets/images/checkout/testimonials/second.mp4';
import third from 'assets/images/checkout/testimonials/third.mp4';
import forth from 'assets/images/checkout/testimonials/forth.mp4';
import fifth from 'assets/images/checkout/testimonials/fifth.mp4';

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const ResultsReviews: FC = () => {
  const [loading, setLoading] = useState(false);
  const data = useQuizData('resultsReviews');
  const { goToCheckout } = useRouter();
  const VideoMap = [first, second, third, forth, fifth];
  //   const nextStep = () => {
  //     setLoading(true);
  //     goToCheckout({ discount: false });
  //   };
  const handleClick = () => {
    setLoading(true);
    goToCheckout({ discount: false });
  };
  return (
    <>
      <Seo />
      <Outter>
        <Header color="novaPink" logoVariant="center" sticky={false} />
        <ResultsProgress
          step={data?.currentStep}
          totalSteps={data?.totalSteps}
        />
      </Outter>
      <Container>
        <InnerContainer>
          <Title dangerouslySetInnerHTML={{ __html: data?.title }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />

          <ListContainer>
            {data?.list.map((item, index) => (
              <ListItem key={index}>
                <ImgContainer>
                  <StyledVideo
                    crossOrigin="anonymous"
                    aria-label="Video"
                    autoPlay
                    loop
                    muted
                    playsInline
                    preload="metadata"
                    x-webkit-airplay="allow"
                    width="100%"
                    height="100%"
                  >
                    <source src={VideoMap[index]} type="video/mp4" />
                  </StyledVideo>
                </ImgContainer>
                <TextContainer>
                  <Top>
                    <ListTitle>{item.title}</ListTitle>
                    <ListText>{item.review}</ListText>
                  </Top>
                  <BottomContainer>
                    <Name>{item.name}</Name>
                    <ImgStyled src="results/verified.png" alt="" />
                  </BottomContainer>
                </TextContainer>
              </ListItem>
            ))}
          </ListContainer>
          <ButtonContainer>
            <ButtonStyled
              loading={loading}
              disabled={loading}
              onClick={handleClick}
            >
              <ArrowConatiner>
                <Arrow />
              </ArrowConatiner>
              {data?.buttonTitle}
            </ButtonStyled>
          </ButtonContainer>
        </InnerContainer>
      </Container>
    </>
  );
};

export default ResultsReviews;

const StyledVideo = styled.video`
  object-fit: cover;
  width: 6.125rem;
`;

const Container = styled.section`
  padding: 3rem 1rem;
  background: #fef3f3;
  min-height: calc(100vh - 76px);
  @media ${tablet} {
    min-height: calc(100vh - 60px);
    padding: 1.5rem 1rem;
  }
`;

const InnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 26.25rem;
  margin: 0 auto;
`;

const Title = styled(Text)`
  color: #2a4b42;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  span {
    display: block;
    color: #ea7a74;
  }
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled(Text)`
  color: #2a4b42;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 0.5rem 0 1.5rem;
  span {
    font-weight: 600;
  }
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const TextContainer = styled.section`
  display: flex;
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ArrowConatiner = styled.section`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 62.5rem;
  background: #fff;
  svg {
    width: 0.63538rem;
    height: 0.625rem;
  }
`;

const ButtonContainer = styled.section`
  width: 100%;
  max-width: 28.26rem;
  margin: 0 auto;
`;

const ButtonStyled = styled(PrimaryButton)`
  width: 100%;
  div {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
`;

const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2.625rem;
  @media ${tablet} {
    margin-bottom: 1.5rem;
  }
`;

const ListTitle = styled(Text)`
  color: #2a4b42;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const ListItem = styled.section`
  display: flex;
  gap: 1rem;
  overflow: hidden;
  border-radius: 0.5rem;
  background: #fff;
  height: 9.25rem;
`;

const Top = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
`;

const ListText = styled(Text)`
  color: #2a4b42;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Name = styled(Text)`
  color: #2a4b42;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
`;

const BottomContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ImgContainer = styled.section`
  width: 6.125rem;
`;

const StyledImg = styled(DynamicImage)`
  width: 6.125rem;
  height: 100%;
`;

const ImgStyled = styled(DynamicImage)`
  height: 1.125rem;
  width: 4rem;
`;
